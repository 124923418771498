import "core-js/stable";
import "regenerator-runtime/runtime";
import Plyr from 'plyr';

const players = Array.from(document.querySelectorAll('.js-plyr')).map(p => new Plyr(p, {
  controls: [
    'play-large',
    'play',
    'progress',
    'current-time',
    'fullscreen'
  ]
}));
